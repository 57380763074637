import ConstructionClock from './components/ConstructionClock';
import TwinklingSky from './components/TwinklingSky';

function App() {
  return (
    <div className="relative flex flex-col justify-center items-center h-screen w-screen bg-night-sky">
      <ConstructionClock className="z-10" />
      <TwinklingSky className="absolute top-0 left-0 right-0 bottom-0" />
    </div>
  );
}

export default App;