import { useEffect, useState } from 'react';

function ConstructionClock(props) {
  const [constructionTime, setConstructionTime] = 
    useState(Math.floor((Date.now() / 1000) - process.env.REACT_APP_GENESIS_TIMESTAMP));
  const daysUnderConstruction = Math.floor(constructionTime / (60*60*24));
  
  useEffect(() => {
    const interval = setInterval(() => {
      setConstructionTime(constructionTime => constructionTime + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="square-container">
      <div className="text-center inline-block">
        <p className="font-mono text-sky-300">under construction for {daysUnderConstruction} days</p>      
      </div>
    </div>
  );
}

export default ConstructionClock;