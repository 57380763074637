import React from 'react';

function TwinklingSky() {
  return (
    <div className="w-full h-screen bg-purple-1200">
      {Array.from(Array(200)).map((_, i) => (
        <span
          key={i}
          className="absolute rounded-full bg-white"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            width: `${Math.random() * 3}px`,
            height: `${Math.random() * 3}px`,
            animation: `twinkle ${Math.random() * 4 + 1}s linear infinite ${
              Math.random() * 2 + 1
            }s`,
          }}
        ></span>
      ))}
    </div>
  );
}

export default TwinklingSky;